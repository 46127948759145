import { Injectable } from '@angular/core';
import { AuthService } from '@blueprint/auth/auth.service';
import * as jwtDecode from 'jwt-decode';
import { DataService } from '@shared/services/data.service';

@Injectable()
export class UserService {

  constructor(private auth: AuthService,
              private dataService: DataService) {}

  /**
   * Checks if the current user has the given role
   * @param roleCode Role code
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();
    return jwt.role === roleCode;
  }

   /**
    * Checks if the current user had administrator rights
    */
  public isAdministrator(): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === 'superuser' || jwt.role === 'administrator';
  }

  public async getUserSign(): Promise<string|null> {
    const jwt = this.getTokenAsObject();
    let userSign: string = null;

    if (jwt && jwt.sub) {
      const user = await this.dataService.getById('users', jwt.sub);

      if (user && user.signature_image) {
        userSign = user.signature_image;
      }
    }

    return userSign;
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    // Get current JWT token
    const jwtToken = this.auth.getToken();

    // Decode JWT token
    return jwtDecode(jwtToken);
  }
}
