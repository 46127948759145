import { DomainModel } from '@domain/domain.model';

export class Activity extends DomainModel {
  // Configuration
  public entity = 'activity';
  public table = 'activities';
  public schema = '++id, remote_id, project_type';
  public sync = true;

  // Fields
  public project_type: string;
  public project_type_label?: string;
  public name: string;
  public code: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  public static listByProjectType(projectType: string) {
    return Activity.query.where('project_type').equals(projectType).toArray();
  }
}
