import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-activities-list',
  templateUrl: './activities-list.component.html',
  styleUrls: ['./activities-list.component.scss']
})
export class ManageActivitiesListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Type', name: 'project_type', filter: { global: false } },
        { title: 'Naam', name: 'name', filter: { global: true } },
      ],
      endpoint: '/activities',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'name' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.project_type = row.project_type === 'private' ? 'Particulier' : 'Zakelijk';
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/activities(popup:admin/manage/activities/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/activities(popup:admin/manage/activities/add)');
  }
}
