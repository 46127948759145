import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss']
})
export class ManageOptionsListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'Type', name: 'relation_group_name', filter: { global: false } },
        { title: 'Prijs excl.', name: 'cost_rate', filter: { global: false } },
        { title: 'BTW %', name: 'vat_rate', filter: { global: false } },
      ],
      endpoint: '/options',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'name' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.relation_group_name = row.relation_group && row.relation_group.name ? row.relation_group.name : '-';
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/options(popup:admin/manage/options/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/options(popup:admin/manage/options/add)');
  }
}
