import { Component, OnInit, ViewChild } from '@angular/core';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableComponent, OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Router } from '@angular/router';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';
import * as jwt_decode from 'jwt-decode';
import { OnlineStatusService } from '@shared/services/online-status.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Subject } from 'rxjs';
import { first } from '@node_modules/rxjs/internal/operators';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html',
  styleUrls: ['project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit {
  @ViewChild('projectSearchTable')
  projectSearchTable: OnlineTableComponent;

  @ViewChild('projectOpenTable')
  projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  public projectSearchTableOptions: OnlineTableOptions;
  public displayModal = false;
  public selectedProjectType = 'private';
  public specialties: any[] = [];
  public activities: any[] = [];
  public relationGroupList: any[] = [];
  public numberOfSpecialties = 0;
  public numberOfActivities = 0;
  public onlineMode = true;
  public statusFilerOptions: SelectItem[] = [{ label: 'Alles', value: '' }];
  public searchTableReady = false;
  public $searchTableSubject = new Subject<void>();

  public constructor(public api: ApiServiceWithLoaderService,
                     private projectService: ProjectService,
                     private synchronisationService: SynchronisationService,
                     private confirmationService: ConfirmationService,
                     private router: Router,
                     private userService: UserService,
                     private onlineStatusService: OnlineStatusService,
  ) {
    this.onlineMode = navigator.onLine;
    this.statusFilerOptions = [...this.statusFilerOptions, ...Project.getStatusList()];
  }

  public async ngOnInit(): Promise<any> {
    this.api.increaseLoaderValueByOne();

    this.api.get('/relation-group/list').subscribe((result) => {
      this.relationGroupList = [{ value: '', label: 'Alles' }, ...result.data];
    });

    this.$searchTableSubject.pipe().subscribe(() => {
      this.loadSearchProjectTable();
    });

    this.loadOpenProjectTable();
    await this.synchronisationService.updateEditingByFlags();
    // Always synchronise when opening project overview
    await this.synchronisationService.synchronise();

    this.api.decreaseLoaderValueByOne();
  }

  public loadOpenProjectTable(): void {
    this.searchTableReady = false;

    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Referentie', name: 'reference_nr' },
        { title: 'Klant', name: 'clientName' },
        { title: 'Status', name: 'status' },
        { title: 'Accountbeheerder', name: 'accountmanagerName' }
      ],
      withDelete: true,
      url: '/projects',
      tableName: 'projects',
      search: false,
      rowDataTransformer: (rows: any) => {
        for (const row of rows) {
          row.reference_nr_display = row.reference_nr ? row.reference_nr : '*Wordt gegenereerd na synchronisatie*';
          row.status = Project.getStatusName(row.status);
        }

        this.$searchTableSubject.next();

        return rows;
      },
    });
  }

  public loadSearchProjectTable(): void {
    this.projectSearchTableOptions = new OnlineTableOptions({
      columns: [
        {
          title: 'Relatiegroep',
          name: 'clientRelationGroupName',
          filter: {
            type: 'select',
            field: 'clients.relation_group_id',
            options: this.relationGroupList,
          },
        },
        {
          title: 'Klant',
          name: 'clientName',
          filter: { global: true, field: 'clients.name' },
        },
        {
          title: 'Referentie',
          name: 'reference_nr',
          filter: {
            global: true,
          },
        },
        {
          title: 'Status',
          name: 'status',
          filter: {
            type: 'select',
            options: this.statusFilerOptions,
          },
        },
        {
          title: 'Accountbeheerder',
          name: 'accountmanagerName',
          filter: { global: true, field: 'accountmanagers.name' },
        },
        {
          title: 'In bewerking',
          name: 'editorName',
          filter: { global: true, field: 'editor.name' },
        },
        {
          title: 'Gesynchroniseerd met de planning',
          name: 'is_synced_with_pcm',
          type: 'yes_no'
        },
      ],
      endpoint: '/projects',
      search: true,
      withDelete: this.userService.isAdministrator(),
      defaultFilters: { 'status': { value: '' } },
      rowDataTransformer: rows => {
        for (const row of rows) {
          const status = this.statusFilerOptions.find(key => key.value === row.status);
          row.status = status ? status.label : row.status;
          row.clientRelationGroupName = row.client.relation_group.name;
          row.accountmanagerName = row.accountmanager.name;
          row.clientName = row.client.name;
          row.editorName = row.editor ? row.editor.name : '';
        }

        return rows;
      },
    });

    this.searchTableReady = true;
  }

  public async onOpenProjectTableRowClick(data: any): Promise<void> {
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async onSearchProjectTableRowClick(data: any): Promise<void> {
    if (data.editing_by) {
      this.confirmationService.confirm({
        message: 'Dit project wordt al bewerkt door ' + data.editor,
        header: 'Waarschuwing!',
        icon: 'fa fa-question-circle',
        acceptLabel: 'Openen in lees-modus',
        rejectLabel: 'Sluiten',
        accept: async _ => {
          await this.openSelectedProject(data);
        },
      });
    } else {
      await this.openSelectedProject(data);
    }
  }

  public async openSelectedProject(data: any): Promise<void> {
    // Load project data from server and then navigate to detail view
    this.projectSearchTable.loading = true;
    await this.synchronisationService.loadSingleProjectData(data.id);
    this.projectSearchTable.loading = false;

    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async addNewProject(): Promise<void> {
    const project = await this.projectService.newProject();
    this.router.navigateByUrl(`/admin/project/${ project.id }/client`);
  }

  public async closeOpenProjects(): Promise<void> {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      toastr.warning('Geen projecten geselecteerd');

      return;
    }

    let notSyncableProjectReferences = '';
    for (let i = 0; i < this.projectOpenTable.selectedRows.length; i++) {
      const project: Project = this.projectOpenTable.selectedRows[i];

      if (project.editing_by && +project.editing_by !== +jwt_decode(localStorage.getItem('token')).sub) {
        notSyncableProjectReferences += (project.reference_nr + '\n');
      }
    }

    this.confirmationService.confirm({
      message: notSyncableProjectReferences.length > 0 ?
          'Let op! De volgende projecten worden niet gesynchroniseerd bij het sluiten:\n ' + notSyncableProjectReferences :
          'Let op! De volgende projecten worden gesynchroniseerd en gesloten.',
      header: 'Bevestiging',
      acceptLabel: 'Akkoord',
      rejectLabel: 'Annuleren',
      icon: 'fa fa-question-circle',
      accept: async _ => {
        this.api.increaseLoaderValueByOne();
        this.$searchTableSubject.pipe(first()).subscribe(() => {
          this.loadSearchProjectTable();
        });

        this.onlineStatusService.checkStatus().subscribe(async isOnline => {
          if (isOnline === true) {
            this.projectOpenTable.loading = true;

            const closeProjectIds: number[] = this.projectOpenTable.selectedRows.map(row => row.id);
            const result = await this.synchronisationService.syncToBackend(true, closeProjectIds);

            if (!result) {
              toastr.error('Projecten synchroniseren sluiten mislukt. Controleer uw verbinding.');
              return;
            }

            // Retrieve open projects except selected rows
            for (const row of this.projectOpenTable.rows) {
              if (closeProjectIds.indexOf(row.id) === -1) {
                await this.synchronisationService.loadSingleProjectData(row.id);
              }
            }

            this.loadOpenProjectTable();
            await this.synchronisationService.updateEditingByFlags();
            this.projectOpenTable.loading = false;

            await this.synchronisationService.synchronise();
          } else {
            toastr.warning('Mogelijk geen stabiele internetverbinding. Projecten niet gesynchroniseerd!');
          }

          this.api.decreaseLoaderValueByOne();
        });
      },
    });
  }

  /**
   * Toggle display boolean for showing the print modal
   *
   * @param event
   */
  public toggleModalDisplay(event: any): void {
    this.displayModal = true;
  }

  /**
   * Print the blank invoice based on project type
   */
  public printBlankInvoice(): void {
    this.displayModal = false;

    const divToPrint = document.getElementById('blank_invoice_pdf');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
        ' <head>' + head.innerHTML + '</head>' +
        ' <body>' +
        '  <img src="/assets/images/logo.png" style="width: 175px;"><br /><br />' +
        '   ' + divToPrint.innerHTML + '' +
        ' </body>' +
        '</html>');

    const elements = popupWin.document.getElementsByTagName('h5');
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  public setSpecialtiesLength(): void {
    if (!(Number(this.numberOfSpecialties) && Number(this.numberOfSpecialties < 15))) {
      return;
    }

    const length = Number(this.numberOfSpecialties);

    this.specialties = [];
    for (let i = 0; i < length; i++) {
      this.specialties.push(i);
    }
  }

  public setActivitiesLength(): void {
    if (!(Number(this.numberOfActivities) && Number(this.numberOfActivities < 15))) {
      return;
    }

    const length = Number(this.numberOfActivities);
    this.activities = [];
    for (let i = 0; i < length; i++) {
      this.activities.push(i);
    }
  }

  public setType(projectType: string): void {
    this.selectedProjectType = projectType;
  }

  private transformRows(rows: Array<any>): any {
    // Determine open projects
    const openProjectIDs = this.projectOpenTable.rows ? this.projectOpenTable.rows.map(project => project.id) : [];
    const selectedProjectIds = this.projectOpenTable.selectedRows ? this.projectOpenTable.selectedRows.map(project => project.id) : [];
    const hideProjectIds = openProjectIDs.filter(projectID => !selectedProjectIds.includes(projectID));
    const existingRows = rows.filter(row => !hideProjectIds.includes(row.id));

    for (const row of existingRows) {
      row.client = row.client ? row.client.name : '';
      row.accountmanager = row.accountmanager ? row.accountmanager.name : '';
      row.editor = row.editor ? row.editor.name : '';
      row.status = Project.getStatusName(row.status);
    }

    return existingRows;
  }
}
