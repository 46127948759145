import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  CanDeactivate
} from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html'
})
export class InventoryProjectComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public project = new Project({});
  public errors: any = {};
  public showErrors = false;

  private subscriptionProjectLoaded: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private projectService: ProjectService
  ) {}

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.initForm();

    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(
      project => {
        this.project = project;
        this.initForm();
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
    this.saveProject();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      reference_nr: this.formBuilder.control(
        this.project.reference_nr || '',
        Validators.required
      )
    });
  }

  public saveProject(): void {
    if (this.form.valid && this.projectService.project) {
      this.projectService.project.reference_nr = this.form.value.reference_nr;
    }
  }
}
