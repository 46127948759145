import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectService } from '@shared/services/project.service';
import { SelectItem } from 'primeng/primeng';
import { Inventory } from '@domain/models/inventory.model';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventory-add',
  templateUrl: 'inventory-add.component.html'
})
export class InventoryAddComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public showErrors = false;
  public project = new Project({});
  public defaultInventories: SelectItem[] = [];

  private inventory = new Inventory({});
  private subscriptionProjectLoaded: Subscription;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private projectService: ProjectService) {}

  public async ngOnInit(): Promise<void> {
    this.project = this.projectService.getProject();

    this.defaultInventories = await this.projectService.getDefaultInventories();

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(project => {
      this.project = project;
    });

    this.initForm();
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.inventory.id),
      name: this.formBuilder.control('', Validators.required),
      building: this.formBuilder.control(this.project.type === 'private' ? 'huis' : '', Validators.required),
      floor: this.formBuilder.control(this.project.type === 'private' ? 0 : '', Validators.required),
      default_inventory_id: this.formBuilder.control(this.defaultInventories[0] ? this.defaultInventories[0].value : '', Validators.required)
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.inventory = new Inventory({ ...this.form.value });
      this.inventory.project_id = this.project.id;

      await this.projectService.saveNewInventory(this.inventory);

      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory');
  }
}
